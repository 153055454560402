// Vendor  node_modules jQuery
import jQuery from 'jquery';
window.$ = window.jQuery = jQuery;

require('slick-carousel');
require('@fancyapps/fancybox');

var group = $("a[rel='video']");
group.attr('data-fancybox', 'fancybox-group');

$(".wp-block-gallery a:has(img)").attr('data-fancybox', 'gal').fancybox();


// Scripts
require('./modules/scripts');


// https://michalsnik.github.io/aos/
// jQuery( document ).ready(function( $ ) {
//   var AOS = require('aos');
//   AOS.init({duration:800,easing:'slide',once:true});
// });



	









