
// Mobile > all touchstar DOM perfect Iphone
document.addEventListener("touchstart", function(){}, true);


document.addEventListener('DOMContentLoaded', function() {

  if(document.body.classList.contains('single-product')) {
    document.querySelectorAll('a[href$=".pdf"]').forEach(function(link) {
      link.target = '_blank';
    });
  }


  $slick = $('#js__homeSlider');
  $slick.slick({
    lazyLoad: 'ondemand',
    arrows: true,
    dots:true,
    autoplay: true,
    autoplaySpeed: 7500,
    infinite: true,
    fade: true,
    pauseOnFocus: false,
    pauseOnHover: true,
    speed: 600,
    slidesToScroll: 1,
  });

  var dots = $slick.find('.slick-dots li');
  if (dots.length > 20) {
    $('.c-slider-home--category .slick-dots').css('bottom', '-50px');
  }
  if (dots.length > 20 && $(window).width() < 768) {
    $('.c-slider-home--category .slick-dots').css('bottom', '-70px');
  }


// Obtén todos los enlaces con una clase .item-open en .c-category-menu
var links = document.querySelectorAll('.c-category-menu .item-open');

//console.log(links); // Debug: Deberías ver todos los elementos seleccionados aquí

// Añade un escuchador de eventos a cada uno de ellos
links.forEach(function(link) {
  link.addEventListener('click', function(event) {

    // Prevén el comportamiento predeterminado del enlace
    event.preventDefault();

    // Busca .item-icon dentro del enlace y alterna la clase item-icon--open
    var itemIcon = link.querySelector('.item-icon');
    if(itemIcon) { 
      itemIcon.classList.toggle('item-icon--open');
      // console.log(itemIcon); // Debug: Deberías ver el elemento que se le ha hecho click
    }

    // Encuentra el elemento <li> más cercano en la jerarquía y alterna la clase .is-active
    var parentLi = link.closest('li');
    if(parentLi) { 
      parentLi.classList.toggle('is-active');
      // console.log(parentLi); // Debug: Deberías ver el elemento <li> padre
    }
  });
});

$('.c-category-menu__submenu .is-active').parents('li').addClass('is-active').find('.item-icon').addClass('item-icon--open');





    // Menu mobile
    var hamburger = document.getElementById('hamburger');
    var html = document.getElementById('html');
    var body = document.body;
    var header = document.getElementById('header');

    var closeDiv = document.createElement('div');
    closeDiv.classList.add('e-close-div');
    closeDiv.style.position = 'fixed';
    closeDiv.style.top = 0;
    closeDiv.style.left = 0;
    closeDiv.style.width = '100vw';
    closeDiv.style.height = '100vh';
    closeDiv.style.zIndex = 0;

    closeDiv.addEventListener('click', function() {
      hamburger.classList.remove('is-active');
      html.classList.remove('is-active-menu-mobile');
      header.removeChild(closeDiv);
    });

    hamburger.addEventListener('click', function() {
      if (!this.classList.contains('is-active')) {
        this.classList.add('is-active');
        html.classList.add('is-active-menu-mobile');
        header.appendChild(closeDiv);
      } else {
        this.classList.remove('is-active');
        html.classList.remove('is-active-menu-mobile');
        if (header.contains(closeDiv)) {
          header.removeChild(closeDiv);
        }
      }
    });



    window.addEventListener('scroll', function() {
      var headerHeight = header.offsetHeight;

      if (window.scrollY > headerHeight) {
        html.classList.add('is-scroll-active');
      } else {
        html.classList.remove('is-scroll-active');
      }
    });




  });





